<template>
  <v-app>
    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ title }}</v-toolbar-title
      ><v-spacer />
      <span style="color: #c1c1c1; font-weight: bold">
        {{ currentOrganization.organization }}</span
      >
      <v-avatar class="ml-2" size="30">
        <img :src="currentOrganization.image" alt="avatar" />
      </v-avatar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" bottom color="#1e1e2d" dark app>
      <div style="background-color: #1a1a27" class="pa-1">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="50"
        />
      </div>
      <v-list-item
        link
        :to="drawerItem.link"
        v-for="drawerItem in drawerItems"
        :key="drawerItem.title"
      >
        <v-list-item-icon>
          <v-icon>{{ drawerItem.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ drawerItem.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div class="mx-4">
        <v-btn
          class="mt-3"
          rounded
          block
          color="primary"
          to="/logout"
          elevation="5"
        >
          <v-icon right dark class="mx-2"> mdi-power-standby</v-icon> Logout
        </v-btn>
      </div>
    </v-navigation-drawer>
    <v-main style="background-color: #edeff7">
      <v-container grid-list-xl>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from "vuex";

export default {
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(to, _) {
        const title = to.meta.title || "Truya";
        this.title = title;
        document.title = title;
      },
    },
  },
  data() {
    return {
      title: "Truya",
      drawer: true,
      drawerItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          link: "/",
        },
        {
          title: "Corporate Users",
          icon: "mdi-account-supervisor-outline",
          link: "/corporate-users",
        },
        {
          title: "Usage and Billings",
          icon: "mdi-chart-box-outline",
          link: "/usage-and-billings",
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          link: "/settings",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
};
</script>
